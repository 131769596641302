<template>
  <validation-provider
    :name="$t(veeName)"
    :rules="veeRules"
    v-slot="validationContext"
  >
    <b-form-group
      label-class="font-weight-bold"
      :label="$t(label)"
      :label-for="veeName"
    >
      <b-form-datepicker
        v-bind="{ ...$attrs, ...(labels[locale] || {}) }"
        v-on="$listeners"
        class="datepicker"
        :locale="locale"
        hide-header
        label-help=""
        :date-format-options="format"
        :state="getValidationState(validationContext)"
      ></b-form-datepicker>
      <b-form-invalid-feedback>
        {{ validationContext.errors[0] }}
      </b-form-invalid-feedback>
    </b-form-group>
  </validation-provider>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: ""
    },
    veeName: {
      type: String,
      default: ""
    },
    veeRules: {
      type: [String, Object],
      default: null
    }
  },
  data() {
    return {
      format: {
        year: "numeric",
        month: "long",
        day: "numeric"
      },
      labels: {
        it: {
          labelPrevDecade: this.$t("datepicker.labelPrevDecade"),
          labelPrevYear: this.$t("datepicker.labelPrevYear"),
          labelPrevMonth: this.$t("datepicker.labelPrevMonth"),
          labelCurrentMonth: this.$t("datepicker.labelCurrentMonth"),
          labelNextMonth: this.$t("datepicker.labelNextMonth"),
          labelNextYear: this.$t("datepicker.labelNextYear"),
          labelNextDecade: this.$t("datepicker.labelNextDecade"),
          labelNoDateSelected: this.$t("datepicker.labelNoDateSelected")
        }
      }
    };
  },
  computed: {
    locale() {
      return this.$i18n.locale;
    }
  },
  methods: {
    getValidationState({ valid, touched }) {
      if (touched) return valid ? null : valid;
      else return null;
    }
  }
};
</script>

<style lang="scss">
.datepicker {
  /deep/ {
    .form-control {
      text-transform: capitalize;
    }
  }
}
</style>
