<template>
  <div>
    <h3 class="mb-5 text-secondary">{{ $t("page.condominium.title") }}</h3>
    <condominium-filters class="mb-5" />
    <div class="mb-5">
      <b-row class="no-gutters justify-content-between align-items-center mb-3">
        <b-col class="col-auto">
          <h4 v-if="showProCondominiums" class="font-weight-normal mb-0">
            {{ $t("active") }}
          </h4>
        </b-col>
        <b-col class="col-auto">
          <b-button size="md" variant="secondary" @click="openCreateModal">
            {{ $t("button.createCondominium") }}
          </b-button>
        </b-col>
      </b-row>
      <condominium-table
        v-if="showProCondominiums"
        :condominiums="activeCondominiums"
        :fields="activeTableOptions.fields"
        :actions="activeTableOptions.actions"
        :sortBy.sync="sortByActive"
        :sortDesc.sync="sortDescActive"
      />
    </div>
    <div v-if="showProHoldCondominiums">
      <h4 class="font-weight-normal mb-3">
        {{ $t("toActivate") }}
      </h4>
      <condominium-table
        :condominiums="pendingCondominiums"
        :fields="pendingTableOptions.fields"
        :actions="pendingTableOptions.actions"
        :sortBy.sync="sortByPending"
        :sortDesc.sync="sortDescPending"
      />
    </div>
    <div v-if="showFreeCondominiums" class="mt-5">
      <h4 class="font-weight-normal mb-3">
        {{ $t("freeCondominium") }}
      </h4>
      <condominium-table
        :condominiums="freeCondominiums"
        :fields="freeTableOptions.fields"
        :actions="freeTableOptions.actions"
        :sortBy.sync="sortByFree"
        :sortDesc.sync="sortDescFree"
      />
    </div>
    <create-condominium :state="createModalState" @close="closeCreateDialog" />
    <activate-condominium
      :state.sync="activateModalState"
      :condominium="selectedCondominium"
    />
    <assign-condominium
      :state.sync="assignModalState"
      :condominium="selectedCondominium"
    />
    <parameters-modal
      :state.sync="parametersModal"
      :condominium="selectedCondominium"
    />
  </div>
</template>

<script>
import { authMixin, appMixin } from "./../mixins";
import { EventBus } from "./../eventBus.js";

import CondominiumFilters from "./../components/Condominium/CondominiumFilters";
import CondominiumTable from "./../components/Condominium/CondominiumTable";
import CreateCondominium from "./../components/Condominium/CreateCondominium";
import ActivateCondominium from "./../components/Condominium/ActivateCondominiumModal";
import AssignCondominium from "./../components/Condominium/AssignCondominiumModal";
import ParametersModal from "./../components/Condominium/ParametersModal";
export default {
  components: {
    CondominiumFilters,
    CondominiumTable,
    CreateCondominium,
    ActivateCondominium,
    AssignCondominium,
    ParametersModal
  },
  mixins: [authMixin, appMixin],
  data() {
    return {
      address: "",
      createModalState: false,
      activateModalState: false,
      assignModalState: false,
      selectedCondominium: null,
      parametersModal: false
    };
  },
  mounted() {
    EventBus.$on("eventBusReloadCondominiums", this.retrieveCondominiums);
  },
  computed: {
    condominiums() {
      return this.$store.getters["condominium/getFilteredCondominiums"];
    },
    showFreeCondominiums() {
      const administratorFilter = this.$store.getters[
        "filter/getAdministratorSelected"
      ];
      const condominiumStatusFilter = this.$store.getters[
        "condominium/getFilterCondominiumStatus"
      ];
      return (
        this.$_authUserIsDomi &&
        administratorFilter === null &&
        (condominiumStatusFilter === null || condominiumStatusFilter === "free")
      );
    },
    showProCondominiums() {
      const condominiumStatusFilter = this.$store.getters[
        "condominium/getFilterCondominiumStatus"
      ];
      return (
        condominiumStatusFilter === null || condominiumStatusFilter === "pro"
      );
    },
    showProHoldCondominiums() {
      const condominiumStatusFilter = this.$store.getters[
        "condominium/getFilterCondominiumStatus"
      ];
      return (
        condominiumStatusFilter === null ||
        condominiumStatusFilter === "pro-hold"
      );
    },
    activeCondominiums() {
      return this.condominiums
        .filter(c => {
          return c.status === "pro";
        })
        .map(item => {
          return {
            id: item._id,
            name: item.name,
            address: this.formatAddress(item),
            expiration: item.expiration,
            unitsNum: this.$is1Board ? item.peopleNum : item.unitsNum,
            administrator: this.getAdministratorName(item.ownerId)
          };
        });
    },
    pendingCondominiums() {
      return this.condominiums
        .filter(c => {
          return c.status === "pro-hold";
        })
        .map(item => {
          return {
            id: item._id,
            name: item.name,
            address: this.formatAddress(item),
            expiration: item.expiration,
            unitsNum: this.$is1Board ? item.peopleNum : item.unitsNum,
            administrator: this.getAdministratorName(item.ownerId)
          };
        });
    },
    freeCondominiums() {
      return this.condominiums
        .filter(c => {
          return c.status === "free";
        })
        .map(item => {
          return {
            id: item._id,
            name: item.name,
            address: this.formatAddress(item),
            expiration: item.expiration,
            unitsNum: item.unitsNum,
            administrator: "-"
          };
        });
    },
    defaultTableFields() {
      const defaultFields = [
        { key: "name", label: "name", sort: true, thStyle: { width: "20%" } },
        {
          key: "address",
          label: "address",
          sort: true,
          thStyle: { width: "30%" }
        },
        {
          key: "expiration",
          label: "expiration",
          sort: true,
          thStyle: { width: "20%" }
        },
        {
          key: "unitsNum",
          label: this.$is1Board ? "users" : "uiNumber",
          sort: true,
          thStyle: { width: "10%" }
        },
        { key: "actions", label: "", sort: false, thStyle: { width: "20%" } }
      ];
      if (this.$_authUserIsDomi) {
        defaultFields.splice(defaultFields.length - 1, 0, {
          key: "administrator",
          label: "administrator",
          sort: true,
          thStyle: { width: "10%" }
        });
        defaultFields[1].thStyle.width = "20%";
        defaultFields[defaultFields.length - 1].thStyle.width = "20%";
      }
      return defaultFields;
    },
    activeTableOptions() {
      const options = {
        fields: this.defaultTableFields,
        actions: [
          {
            label: "button.manage",
            callbackFunc: this.openManagementPage
          }
        ]
      };
      if (this.$_authUserIsDomi) {
        options.actions.push(
          {
            label: "button.tofree",
            callbackFunc: this.openCondominiumStateChangeModal
          },
          {
            label: "button.parameters",
            callbackFunc: this.openParametersModal
          },
          {
            label: "button.delete",
            callbackFunc: this.openDeleteConfirmModal,
            color: "orange"
          }
        );
      }
      return options;
    },
    pendingTableOptions() {
      const options = {
        fields: this.defaultTableFields
      };

      if (this.$_authUserIsDomi) {
        return {
          ...options,
          ...{
            actions: [
              {
                label: "button.activate",
                callbackFunc: this.openActivateModal
              },
              {
                label: "button.delete",
                callbackFunc: this.openDeleteConfirmModal,
                color: "orange"
              }
            ]
          }
        };
      } else return options;
    },
    freeTableOptions() {
      const options = {
        fields: this.defaultTableFields,
        actions: [
          {
            label: "button.manage",
            callbackFunc: this.openManagementPage
          },
          {
            label: "button.assignAdmin",
            callbackFunc: this.openAssignModal
          }
        ]
      };
      if (this.$_authUserIsDomi) {
        options.actions.push({
          label: "button.delete",
          callbackFunc: this.openDeleteConfirmModal,
          color: "orange"
        });
      }
      return options;
    },
    sortByActive: {
      get() {
        return this.$store.getters["condominium/getSortByActive"];
      },
      set(value) {
        this.$store.commit("condominium/SET_SORT_BY_ACTIVE", value);
      }
    },
    sortDescActive: {
      get() {
        return this.$store.getters["condominium/getSortDescActive"];
      },
      set(value) {
        this.$store.commit("condominium/SET_SORT_DESC_ACTIVE", value);
      }
    },
    sortByPending: {
      get() {
        return this.$store.getters["condominium/getSortByPending"];
      },
      set(value) {
        this.$store.commit("condominium/SET_SORT_BY_PENDING", value);
      }
    },
    sortDescPending: {
      get() {
        return this.$store.getters["condominium/getSortDescPending"];
      },
      set(value) {
        this.$store.commit("condominium/SET_SORT_DESC_PENDING", value);
      }
    },
    sortByFree: {
      get() {
        return this.$store.getters["condominium/getSortByFree"];
      },
      set(value) {
        this.$store.commit("condominium/SET_SORT_BY_FREE", value);
      }
    },
    sortDescFree: {
      get() {
        return this.$store.getters["condominium/getSortDescFree"];
      },
      set(value) {
        this.$store.commit("condominium/SET_SORT_DESC_FREE", value);
      }
    }
  },
  methods: {
    openCreateModal() {
      this.createModalState = true;
    },
    closeCreateDialog() {
      this.createModalState = false;
    },
    formatAddress(condominium) {
      return [
        condominium.address,
        condominium.streetNumber,
        condominium.city,
        condominium.province
      ].join(", ");
    },
    getAdministratorName(ownerId) {
      if (
        ownerId &&
        ownerId.profile &&
        ownerId.profile.name &&
        ownerId.profile.surname
      )
        return `${ownerId.profile.name} ${ownerId.profile.surname}`;
      else return "-";
    },
    openManagementPage(condominiumId) {
      this.$store.commit("condominium/SET_ACTIVE_TAB", 0);
      this.$router.push({
        name: "CondominiumManagement",
        params: {
          condominiumId
        }
      });
    },
    openActivateModal(condominiumId) {
      this.selectedCondominium = this.condominiums.find(
        c => c._id === condominiumId
      );
      this.activateModalState = true;
    },
    openAssignModal(condominiumId) {
      this.selectedCondominium = this.condominiums.find(
        c => c._id === condominiumId
      );
      this.assignModalState = true;
    },
    openCondominiumStateChangeModal(condominiumId) {
      this.$store.commit("confirm/SET_CONFIRM", {
        confirmBtnCallback: this.setConsominiumStatusToFree,
        confirmBtnParams: { condominiumId },
        type: "PROCEED"
      });
    },
    openParametersModal(condominiumId) {
      this.selectedCondominium = this.condominiums.find(
        c => c._id === condominiumId
      );
      this.parametersModal = true;
    },
    setConsominiumStatusToFree({ condominiumId }) {
      const selectedCondominium = this.condominiums.find(c => {
        return c._id === condominiumId;
      });
      this.updateCondominium(selectedCondominium, {
        status: "free"
      });
    },
    async retrieveCondominiums() {
      try {
        this.$store.commit("loader/SET_LOADER", {});
        await this.$store.dispatch("condominium/retrieveCondominiumsByFilters");
      } catch (error) {
        console.log(error);
      } finally {
        this.$store.commit("loader/RESET");
      }
    },
    async updateCondominium(condominium, info) {
      try {
        this.$store.commit("loader/SET_LOADER", {});
        await this.$store.dispatch("condominium/updateCondominium", {
          condominiumId: condominium._id,
          payload: {
            name: condominium.name,
            address: condominium.address,
            city: condominium.city,
            province: condominium.province,
            zip: condominium.zip,
            unitsNum: condominium.unitsNum,
            ...info
          }
        });

        await this.$store.dispatch("condominium/retrieveCondominiums");
        await this.$store.dispatch("condominium/retrieveCondominiumsByFilters");
      } catch (error) {
        console.log(error);
      } finally {
        this.$store.commit("loader/RESET");
      }
    },
    openDeleteConfirmModal(condominiumId) {
      const condominium = this.condominiums.find(
        ({ _id }) => _id === condominiumId
      );
      this.$store.commit("confirm/SET_CONFIRM", {
        messageText: "confirm.deleteCondominium",
        messageParams: { condominiumName: condominium.name },
        confirmBtnCallback: this.deleteCondominium,
        confirmBtnParams: { condominiumId },
        type: "PROCEED"
      });
    },
    async deleteCondominium({ condominiumId }) {
      try {
        this.$store.commit("loader/SET_LOADER", {});
        await this.$store.dispatch("condominium/deleteCondominium", {
          condominiumId
        });
        await this.$store.dispatch("condominium/retrieveCondominiums");
        await this.$store.dispatch("condominium/retrieveCondominiumsByFilters");
      } catch (error) {
        console.log(error);
      } finally {
        this.$store.commit("loader/RESET");
      }
    }
  },
  beforeDestroy() {
    EventBus.$off("eventBusReloadCondominiums");
  }
};
</script>

<style lang="scss" scoped>
.list-item-icon {
  width: 20px;
}
</style>
